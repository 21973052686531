/* tslint:disable */
/* eslint-disable */
/**
 * Maritime Connectivity Platform Identity Registry API
 * The MCP Identity Registry API can be used for managing entities in the Maritime Connectivity Platform.<br>Two versions of the API are available - one that requires authentication using OpenID Connect and one that requires authentication using a X.509 client certificate.<br>The OpenAPI descriptions for the two versions are available <a href=\"https://api.aivn.kr/v3/api-docs/mcp-idreg-oidc\">here</a> and <a href=\"https://api-x509.aivn.kr/v3/api-docs/mcp-idreg-x509\">here</a>.
 *
 * OpenAPI spec version: 1.2.1
 * Contact: info@maritimeconnectivity.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Certificate } from '../models';
import { CertificateRevocation } from '../models';
import { PageUser } from '../models';
import { User } from '../models';
/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (body: User, orgMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUser.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling createUser.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser1: async (body: User, orgMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUser1.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling createUser1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling deleteUser.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser1: async (orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling deleteUser1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling deleteUser1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (orgMrn: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getOrganizationUsers.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/users`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers1: async (orgMrn: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getOrganizationUsers1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/users`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getUser.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling getUser.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser1: async (orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getUser1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling getUser1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCert: async (orgMrn: string, userMrn: string, serialNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getUserCert.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling getUserCert.');
            }
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError('serialNumber','Required parameter serialNumber was null or undefined when calling getUserCert.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}/certificate/{serialNumber}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)))
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCert1: async (orgMrn: string, userMrn: string, serialNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling getUserCert1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling getUserCert1.');
            }
            // verify required parameter 'serialNumber' is not null or undefined
            if (serialNumber === null || serialNumber === undefined) {
                throw new RequiredError('serialNumber','Required parameter serialNumber was null or undefined when calling getUserCert1.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}/certificate/{serialNumber}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)))
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUserCertFromCsr: async (body: string, orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling newUserCertFromCsr.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling newUserCertFromCsr.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling newUserCertFromCsr.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}/certificate/issue-new/csr`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/x-pem-file';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUserCertFromCsr1: async (body: string, orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling newUserCertFromCsr1.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling newUserCertFromCsr1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling newUserCertFromCsr1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}/certificate/issue-new/csr`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/x-pem-file';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserCert: async (body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling revokeUserCert.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling revokeUserCert.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling revokeUserCert.');
            }
            // verify required parameter 'certId' is not null or undefined
            if (certId === null || certId === undefined) {
                throw new RequiredError('certId','Required parameter certId was null or undefined when calling revokeUserCert.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}/certificate/{certId}/revoke`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)))
                .replace(`{${"certId"}}`, encodeURIComponent(String(certId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeUserCert1: async (body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling revokeUserCert1.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling revokeUserCert1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling revokeUserCert1.');
            }
            // verify required parameter 'certId' is not null or undefined
            if (certId === null || certId === undefined) {
                throw new RequiredError('certId','Required parameter certId was null or undefined when calling revokeUserCert1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}/certificate/{certId}/revoke`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)))
                .replace(`{${"certId"}}`, encodeURIComponent(String(certId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (body: User, orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUser.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling updateUser.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling updateUser.');
            }
            const localVarPath = `/x509/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser1: async (body: User, orgMrn: string, userMrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUser1.');
            }
            // verify required parameter 'orgMrn' is not null or undefined
            if (orgMrn === null || orgMrn === undefined) {
                throw new RequiredError('orgMrn','Required parameter orgMrn was null or undefined when calling updateUser1.');
            }
            // verify required parameter 'userMrn' is not null or undefined
            if (userMrn === null || userMrn === undefined) {
                throw new RequiredError('userMrn','Required parameter userMrn was null or undefined when calling updateUser1.');
            }
            const localVarPath = `/oidc/api/org/{orgMrn}/user/{userMrn}`
                .replace(`{${"orgMrn"}}`, encodeURIComponent(String(orgMrn)))
                .replace(`{${"userMrn"}}`, encodeURIComponent(String(userMrn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers! = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(body: User, orgMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).createUser(body, orgMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser1(body: User, orgMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).createUser1(body, orgMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).deleteUser(orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).deleteUser1(orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PageUser>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getOrganizationUsers(orgMrn, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PageUser>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getOrganizationUsers1(orgMrn, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getUser(orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getUser1(orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCert(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Certificate>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getUserCert(orgMrn, userMrn, serialNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCert1(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Certificate>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).getUserCert1(orgMrn, userMrn, serialNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUserCertFromCsr(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).newUserCertFromCsr(body, orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUserCertFromCsr1(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).newUserCertFromCsr1(body, orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserCert(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).revokeUserCert(body, orgMrn, userMrn, certId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserCert1(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).revokeUserCert1(body, orgMrn, userMrn, certId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).updateUser(body, orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser1(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<any>>> {
            const localVarAxiosArgs = await UserControllerApiAxiosParamCreator(configuration).updateUser1(body, orgMrn, userMrn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(body: User, orgMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return UserControllerApiFp(configuration).createUser(body, orgMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser1(body: User, orgMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return UserControllerApiFp(configuration).createUser1(body, orgMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).deleteUser(orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).deleteUser1(orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<PageUser>> {
            return UserControllerApiFp(configuration).getOrganizationUsers(orgMrn, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a page of user identities of the specified organization
         * @param {string} orgMrn 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<PageUser>> {
            return UserControllerApiFp(configuration).getOrganizationUsers1(orgMrn, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return UserControllerApiFp(configuration).getUser(orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific user identity
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return UserControllerApiFp(configuration).getUser1(orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCert(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Certificate>> {
            return UserControllerApiFp(configuration).getUserCert(orgMrn, userMrn, serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user identity certificate with the given serial number
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCert1(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Certificate>> {
            return UserControllerApiFp(configuration).getUserCert1(orgMrn, userMrn, serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUserCertFromCsr(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return UserControllerApiFp(configuration).newUserCertFromCsr(body, orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user identity certificate using CSR
         * @param {string} body A PEM encoded PKCS#10 CSR
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUserCertFromCsr1(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return UserControllerApiFp(configuration).newUserCertFromCsr1(body, orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserCert(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).revokeUserCert(body, orgMrn, userMrn, certId, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke the service identity certificate with the given serial number
         * @param {CertificateRevocation} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {number} certId The serial number of the certificate given in decimal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeUserCert1(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).revokeUserCert1(body, orgMrn, userMrn, certId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).updateUser(body, orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific user identity
         * @param {User} body 
         * @param {string} orgMrn 
         * @param {string} userMrn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser1(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
            return UserControllerApiFp(configuration).updateUser1(body, orgMrn, userMrn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * Create a new user identity
     * @param {User} body 
     * @param {string} orgMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async createUser(body: User, orgMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return UserControllerApiFp(this.configuration).createUser(body, orgMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a new user identity
     * @param {User} body 
     * @param {string} orgMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async createUser1(body: User, orgMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return UserControllerApiFp(this.configuration).createUser1(body, orgMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete a specific user identity
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async deleteUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).deleteUser(orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete a specific user identity
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async deleteUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).deleteUser1(orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a page of user identities of the specified organization
     * @param {string} orgMrn 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getOrganizationUsers(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageUser>> {
        return UserControllerApiFp(this.configuration).getOrganizationUsers(orgMrn, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a page of user identities of the specified organization
     * @param {string} orgMrn 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getOrganizationUsers1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageUser>> {
        return UserControllerApiFp(this.configuration).getOrganizationUsers1(orgMrn, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a specific user identity
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getUser(orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return UserControllerApiFp(this.configuration).getUser(orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a specific user identity
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getUser1(orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return UserControllerApiFp(this.configuration).getUser1(orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the user identity certificate with the given serial number
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {number} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getUserCert(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Certificate>> {
        return UserControllerApiFp(this.configuration).getUserCert(orgMrn, userMrn, serialNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the user identity certificate with the given serial number
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {number} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async getUserCert1(orgMrn: string, userMrn: string, serialNumber: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Certificate>> {
        return UserControllerApiFp(this.configuration).getUserCert1(orgMrn, userMrn, serialNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a new user identity certificate using CSR
     * @param {string} body A PEM encoded PKCS#10 CSR
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async newUserCertFromCsr(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return UserControllerApiFp(this.configuration).newUserCertFromCsr(body, orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a new user identity certificate using CSR
     * @param {string} body A PEM encoded PKCS#10 CSR
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async newUserCertFromCsr1(body: string, orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return UserControllerApiFp(this.configuration).newUserCertFromCsr1(body, orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Revoke the service identity certificate with the given serial number
     * @param {CertificateRevocation} body 
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {number} certId The serial number of the certificate given in decimal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async revokeUserCert(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).revokeUserCert(body, orgMrn, userMrn, certId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Revoke the service identity certificate with the given serial number
     * @param {CertificateRevocation} body 
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {number} certId The serial number of the certificate given in decimal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async revokeUserCert1(body: CertificateRevocation, orgMrn: string, userMrn: string, certId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).revokeUserCert1(body, orgMrn, userMrn, certId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update a specific user identity
     * @param {User} body 
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async updateUser(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).updateUser(body, orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update a specific user identity
     * @param {User} body 
     * @param {string} orgMrn 
     * @param {string} userMrn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public async updateUser1(body: User, orgMrn: string, userMrn: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<any>> {
        return UserControllerApiFp(this.configuration).updateUser1(body, orgMrn, userMrn, options).then((request) => request(this.axios, this.basePath));
    }
}
