/* tslint:disable */
/* eslint-disable */
/**
 * SaaS Management Service
 * Management Service for SaaS infrastructure
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Organization } from '../models';
import { User } from '../models';
import { UserServiceSubscription } from '../models';
import { UserServiceUsage } from '../models';
import { UserServiceUsageDto } from '../models';
/**
 * MyUserControllerApi - axios parameter creator
 * @export
 */
export const MyUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activates the UserServiceSubscription with the given ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateSubscription: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activateSubscription.');
            }
            const localVarPath = `/api/my-user/activate-subscription/subscription-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [predictedCost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUseService: async (predictedCost?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/can-use-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (predictedCost !== undefined) {
                localVarQueryParameter['predictedCost'] = predictedCost;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateSubscription: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivateSubscription.');
            }
            const localVarPath = `/api/my-user/deactive-subscription/subscription-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of active subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActiveSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/active-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the organization for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of possible subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPossibleSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/possible-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the service usage for the current user
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyServiceUsage: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/service-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the total cost for the current user. A start date and an end date can optionally be provided.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTotalCost: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/my-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/my-user/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Register service usage for the current user
         * @param {UserServiceUsageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerServiceUsage: async (body: UserServiceUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling registerServiceUsage.');
            }
            const localVarPath = `/api/my-user/service-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyUserControllerApi - functional programming interface
 * @export
 */
export const MyUserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Activates the UserServiceSubscription with the given ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateSubscription(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).activateSubscription(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [predictedCost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canUseService(predictedCost?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).canUseService(predictedCost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateSubscription(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).deactivateSubscription(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the list of active subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyActiveSubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<UserServiceSubscription>>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyActiveSubscriptions(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the organization for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyOrganization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Organization>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyOrganization(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the list of possible subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPossibleSubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<UserServiceSubscription>>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyPossibleSubscriptions(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the service usage for the current user
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyServiceUsage(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<UserServiceUsage>>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyServiceUsage(startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the total cost for the current user. A start date and an end date can optionally be provided.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTotalCost(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<number>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyTotalCost(startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).getMyUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Register service usage for the current user
         * @param {UserServiceUsageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerServiceUsage(body: UserServiceUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserServiceUsage>>> {
            const localVarAxiosArgs = await MyUserControllerApiAxiosParamCreator(configuration).registerServiceUsage(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MyUserControllerApi - factory interface
 * @export
 */
export const MyUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Activates the UserServiceSubscription with the given ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateSubscription(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return MyUserControllerApiFp(configuration).activateSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [predictedCost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canUseService(predictedCost?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return MyUserControllerApiFp(configuration).canUseService(predictedCost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateSubscription(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return MyUserControllerApiFp(configuration).deactivateSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of active subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyActiveSubscriptions(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UserServiceSubscription>>> {
            return MyUserControllerApiFp(configuration).getMyActiveSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the organization for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyOrganization(options?: AxiosRequestConfig): Promise<AxiosResponse<Organization>> {
            return MyUserControllerApiFp(configuration).getMyOrganization(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of possible subscriptions for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPossibleSubscriptions(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UserServiceSubscription>>> {
            return MyUserControllerApiFp(configuration).getMyPossibleSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the service usage for the current user
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyServiceUsage(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UserServiceUsage>>> {
            return MyUserControllerApiFp(configuration).getMyServiceUsage(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the total cost for the current user. A start date and an end date can optionally be provided.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTotalCost(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return MyUserControllerApiFp(configuration).getMyTotalCost(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUser(options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return MyUserControllerApiFp(configuration).getMyUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Register service usage for the current user
         * @param {UserServiceUsageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerServiceUsage(body: UserServiceUsageDto, options?: AxiosRequestConfig): Promise<AxiosResponse<UserServiceUsage>> {
            return MyUserControllerApiFp(configuration).registerServiceUsage(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyUserControllerApi - object-oriented interface
 * @export
 * @class MyUserControllerApi
 * @extends {BaseAPI}
 */
export class MyUserControllerApi extends BaseAPI {
    /**
     * Activates the UserServiceSubscription with the given ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async activateSubscription(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return MyUserControllerApiFp(this.configuration).activateSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [predictedCost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async canUseService(predictedCost?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return MyUserControllerApiFp(this.configuration).canUseService(predictedCost, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async deactivateSubscription(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return MyUserControllerApiFp(this.configuration).deactivateSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the list of active subscriptions for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyActiveSubscriptions(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<UserServiceSubscription>>> {
        return MyUserControllerApiFp(this.configuration).getMyActiveSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the organization for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyOrganization(options?: AxiosRequestConfig) : Promise<AxiosResponse<Organization>> {
        return MyUserControllerApiFp(this.configuration).getMyOrganization(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the list of possible subscriptions for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyPossibleSubscriptions(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<UserServiceSubscription>>> {
        return MyUserControllerApiFp(this.configuration).getMyPossibleSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the service usage for the current user
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyServiceUsage(startDate?: string, endDate?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<UserServiceUsage>>> {
        return MyUserControllerApiFp(this.configuration).getMyServiceUsage(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the total cost for the current user. A start date and an end date can optionally be provided.
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyTotalCost(startDate?: string, endDate?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return MyUserControllerApiFp(this.configuration).getMyTotalCost(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async getMyUser(options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return MyUserControllerApiFp(this.configuration).getMyUser(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Register service usage for the current user
     * @param {UserServiceUsageDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyUserControllerApi
     */
    public async registerServiceUsage(body: UserServiceUsageDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserServiceUsage>> {
        return MyUserControllerApiFp(this.configuration).registerServiceUsage(body, options).then((request) => request(this.axios, this.basePath));
    }
}
